import { motion } from "framer-motion";
import 'primeicons/primeicons.css';
import { AutoComplete } from "primereact/autocomplete";
import { FloatLabel } from 'primereact/floatlabel';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useState } from "react";

function MultiSelectDropdown({ label, options, defaultLabel, value, setValue, selectedMainIngredient, selectedRank1, selectedRank2, selectedRank3, selectedRank4, combinedPairingIngredients, setCombinedPairingIngredients }) {
    return (
        <div className="inline-flex flex-col w-full mt-10 max-w-[80%]">
            <FloatLabel className="w-full md:w-14rem">
                <MultiSelect
                    value={value}
                    options={options}
                    onChange={(e) => {
                        setValue(e.value);

                        // Accumulate the selected ingredients across all ranks
                        setCombinedPairingIngredients((prevIngredients) => {
                            // Remove previously selected items from this rank
                            const remainingIngredients = prevIngredients.filter(
                                (ingredient) => !value.includes(ingredient)
                            );

                            // Add the currently selected items for this rank
                            return [...remainingIngredients, ...e.value];
                        });
                    }}
                    placeholder={defaultLabel}
                    optionLabel="label"
                    className="w-full"
                    disabled={selectedMainIngredient === null}
                />
                <label htmlFor="ms-label">{label}</label>
            </FloatLabel>
        </div>
    );
}

function MainIngredientDropdown({ label, options, value, setValue, setSearchData, searchData }) {
    const ingredientOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center sm:max-w-72 lg:max-w-72">
                <div className="whitespace-normal break-words">{option}</div>
            </div>
        );
    };

    const search = (event) => {
        const query = event.query.toLowerCase();
        const filteredOptions = options.filter(option => option.label.toLowerCase().includes(query));

        const calculateScore = (option, query) => {
            let score = 0;
            if (option.label.toLowerCase().startsWith(query)) {
                score += 10;
            }
            return score;
        };

        const scoredOptions = filteredOptions.map(option => ({ ...option, score: calculateScore(option, query) }));
        scoredOptions.sort((a, b) => b.score - a.score);

        const filteredLabels = scoredOptions.map(option => option.label);
        setSearchData(filteredLabels);
    };

    return (
        <div className="inline-flex flex-col w-full">
            <FloatLabel className="w-full md:w-14rem">
                <AutoComplete
                    value={value}
                    suggestions={searchData}
                    completeMethod={search}
                    onChange={(e) => setValue(e.value)}
                    itemTemplate={ingredientOptionTemplate}
                    className="w-full"
                    dropdown
                    style={{ height: 50 }}
                    inputStyle={{ paddingLeft: 10 }}
                    forceSelection
                    onSelect={(e) => setValue(e.value)}
                />
                {/* <Dropdown
                    value={value}
                    options={options}
                    onChange={(e) => setValue(e.value)}
                    placeholder={defaultLabel}
                    optionLabel="label"
                    itemTemplate={ingredientOptionTemplate}
                    className="w-full"
                    checkmark
                /> */}
                <label htmlFor="dd-main-ingredient">{label}</label>
            </FloatLabel>
        </div>
    );
}

export default function Home() {
    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedMainIngredient, setSelectedMainIngredient] = useState(null);
    const [selectedRank1, setSelectedRank1] = useState([]);
    const [selectedRank2, setSelectedRank2] = useState([]);
    const [selectedRank3, setSelectedRank3] = useState([]);
    const [selectedRank4, setSelectedRank4] = useState([]);
    const [combinedPairingIngredients, setCombinedPairingIngredients] = useState([]);
    const [flavorAffinities, setFlavorAffinities] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://157.230.176.219/api/flavors');
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    // Update flavor affinities when main ingredient changes
    useEffect(() => {
        if (selectedMainIngredient && data.length > 0) {
            const selectedData = data.find(item => item.main_ingredient === selectedMainIngredient);
            if (selectedData) {
                setFlavorAffinities(selectedData.flavor_affinities || []);
            } else {
                setFlavorAffinities([]);
            }
        }
    }, [selectedMainIngredient, data]);

    // Generate options for the Main Ingredient dropdown
    const mainIngredientOptions = data?.map(item => ({
        label: item.main_ingredient,
        value: item.main_ingredient,
    }));

    // Generate options for each level based on the selected main ingredient
    const generateLevelOptions = (level) => {
        const selectedData = data.find(item => item.main_ingredient === selectedMainIngredient);
        const output = selectedData?.[level]?.flatMap(ingredient => {
            // Check if the ingredient contains a colon
            if (ingredient.includes(':')) {
                const [main, subIngredients] = ingredient.split(':');
                // Split the sub-ingredients by comma and trim extra spaces
                return subIngredients.split(',').map(sub => ({
                    label: `${main.trim()}: ${sub.trim()}`,
                    value: `${main.trim()}: ${sub.trim()}`
                }));
            }
            // If no colon, return the ingredient as-is
            return {
                label: ingredient,
                value: ingredient
            };
        }) || [];

        console.log(output);
        return output;
    };

    // Reset selected ranks when main ingredient changes
    useEffect(() => {
        if (selectedMainIngredient !== null) {
            setSelectedRank1([]);
            setSelectedRank2([]);
            setSelectedRank3([]);
            setSelectedRank4([]);
            setCombinedPairingIngredients([]);
        }
    }, [selectedMainIngredient]);

    const handleAffinityClick = (ingredient) => {
        if (!combinedPairingIngredients.includes(ingredient)) {
            setCombinedPairingIngredients([...combinedPairingIngredients, ingredient]);
        }
    };

    const handleRemovePairingIngredient = (ingredient) => {
        setCombinedPairingIngredients((prevIngredients) =>
            prevIngredients.filter((item) => item !== ingredient)
        );

        // Remove the ingredient from the appropriate rank selection
        if (selectedRank1.includes(ingredient)) {
            setSelectedRank1(selectedRank1.filter((item) => item !== ingredient));
        }
        if (selectedRank2.includes(ingredient)) {
            setSelectedRank2(selectedRank2.filter((item) => item !== ingredient));
        }
        if (selectedRank3.includes(ingredient)) {
            setSelectedRank3(selectedRank3.filter((item) => item !== ingredient));
        }
        if (selectedRank4.includes(ingredient)) {
            setSelectedRank4(selectedRank4.filter((item) => item !== ingredient));
        }
    };


    return (
        <div className="min-h-screen p-8 sm:p-8 lg:p-12 bg-gray-100 flex flex-col items-center">
            {loading ? (
                <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-50 backdrop-blur-sm">
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".8s" />
                </div>
            ) : (
                <>
                    <div className="w-full sm:w-72 lg:w-96 mb-8">
                        <MainIngredientDropdown
                            label="Search Main Ingredient"
                            options={mainIngredientOptions}
                            value={selectedMainIngredient}
                            setValue={setSelectedMainIngredient}
                            setSearchData={setSearchData}
                            searchData={searchData}
                        />
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-7xl">
                        <SelectBox
                            label="Rank 1"
                            description="Most highly recommended by the greatest number of experts"
                            borderColor="border-green-300"
                            bgColor="bg-green-50"
                            options={generateLevelOptions('level_4')}
                            value={selectedRank1}
                            setValue={setSelectedRank1}
                            icon="/assets/perfect.svg"
                            selectedMainIngredient={selectedMainIngredient}
                            selectedRank1={selectedRank1}
                            selectedRank2={selectedRank2}
                            selectedRank3={selectedRank3}
                            selectedRank4={selectedRank4}
                            combinedPairingIngredients={combinedPairingIngredients}
                            setCombinedPairingIngredients={setCombinedPairingIngredients}
                        />
                        <SelectBox
                            label="Rank 2"
                            description="Very highly recommended by an even greater number of experts"
                            borderColor="border-yellow-300"
                            bgColor="bg-yellow-50"
                            options={generateLevelOptions('level_3')}
                            value={selectedRank2}
                            setValue={setSelectedRank2}
                            icon="/assets/best.svg"
                            selectedMainIngredient={selectedMainIngredient}
                            selectedRank1={selectedRank1}
                            selectedRank2={selectedRank2}
                            selectedRank3={selectedRank3}
                            selectedRank4={selectedRank4}
                            combinedPairingIngredients={combinedPairingIngredients}
                            setCombinedPairingIngredients={setCombinedPairingIngredients}
                        />
                        <SelectBox
                            label="Rank 3"
                            description="Recommended by a number of experts"
                            borderColor="border-gray-300"
                            bgColor="bg-gray-50"
                            options={generateLevelOptions('level_2')}
                            value={selectedRank3}
                            setValue={setSelectedRank3}
                            icon="/assets/mountain.svg"
                            selectedMainIngredient={selectedMainIngredient}
                            selectedRank1={selectedRank1}
                            selectedRank2={selectedRank2}
                            selectedRank3={selectedRank3}
                            selectedRank4={selectedRank4}
                            combinedPairingIngredients={combinedPairingIngredients}
                            setCombinedPairingIngredients={setCombinedPairingIngredients}
                        />
                        <SelectBox
                            label="Rank 4"
                            description="Suggested by one or more experts"
                            borderColor="border-gray-300"
                            bgColor="bg-gray-50"
                            options={generateLevelOptions('level_1')}
                            value={selectedRank4}
                            setValue={setSelectedRank4}
                            icon="/assets/leaf.svg"
                            selectedMainIngredient={selectedMainIngredient}
                            selectedRank1={selectedRank1}
                            selectedRank2={selectedRank2}
                            selectedRank3={selectedRank3}
                            selectedRank4={selectedRank4}
                            combinedPairingIngredients={combinedPairingIngredients}
                            setCombinedPairingIngredients={setCombinedPairingIngredients}
                        />
                    </div>

                    {/* Display the selected main ingredient and pairing ingredients */}
                    <SelectedIngredientsDisplay
                        mainIngredient={selectedMainIngredient}
                        pairingIngredients={combinedPairingIngredients}
                        onRemove={handleRemovePairingIngredient}
                    />
                    <FlavorAffinitiesDisplay
                        flavorAffinities={flavorAffinities}
                        mainIngredient={selectedMainIngredient}
                        onAffinityClick={(ingredient) => handleAffinityClick(ingredient)}
                    />
                </>
            )}
        </div>
    );
}



function SelectedIngredientsDisplay({ mainIngredient, pairingIngredients, onRemove }) {
    if (!mainIngredient) {
        return null;
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-7xl p-4 py-8 mt-6 bg-orange-300 rounded-[20px] text-white flex flex-col md:flex-row items-start md:items-center "
        >
            <div className="flex items-center mb-4 md:mb-0 md:mr-6">
                <span className="font-semibold mr-2">Main Ingredient:</span>
                <div className="px-3 py-1 bg-lime-400 text-black font-semibold rounded-full">
                    {mainIngredient}
                </div>
            </div>
            <div className="flex flex-wrap items-center">
                <span className="font-semibold mr-2">Pairing Ingredients:</span>
                {pairingIngredients.length > 0 ? (
                    pairingIngredients.map((ingredient, index) => (
                        <div key={index} className="flex items-center mb-2 md:mb-0">
                            <motion.div
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.4 }}
                                className="px-3 py-1 bg-white text-black rounded-full mx-1 mt-1 flex items-center"
                            >
                                {ingredient}
                                <button
                                    className="ml-2 text-red-500 hover:text-red-700"
                                    onClick={() => onRemove(ingredient)}
                                >
                                    &times;
                                </button>
                            </motion.div>
                            {index < pairingIngredients.length - 1 && (
                                <span className="text-white font-semibold mx-1">+</span>
                            )}
                        </div>
                    ))
                ) : (
                    <span className="italic">None selected</span>
                )}
            </div>
        </motion.div>
    );
}

function SelectBox({ label, description, borderColor, bgColor, options, value, setValue, icon, selectedMainIngredient, selectedRank1, selectedRank2, selectedRank3, selectedRank4, combinedPairingIngredients, setCombinedPairingIngredients }) {
    return (
        // <div className={`p-4 border ${borderColor} ${bgColor} rounded-[20px] flex items-center min-h-[200px] flex-col sm:flex-row`}>
        <div
            className={`p-4 border ${borderColor} ${bgColor} rounded-[20px] flex items-center min-h-[200px] flex-col sm:flex-row transition-transform transition-shadow duration-300 ease-in-out hover:scale-105 hover:shadow-lg`}
        >
            <img src={icon} alt={`${label} icon`} className="w-16 h-16 mb-4 sm:mb-0 sm:mr-4" />
            <div className="w-full">
                <h2 className="text-lg font-semibold text-gray-700">{`${label}`}
                    {/* <span className="text-[12px] font-normal text-gray-600"> ({options?.length})</span> */}
                </h2>
                <p className="text-gray-600 mb-2">{description}</p>
                <MultiSelectDropdown
                    label={`${options?.length} Pairing Ingredient`}
                    options={options}
                    defaultLabel="Select Pairing Ingredient"
                    value={value}
                    setValue={setValue}
                    selectedMainIngredient={selectedMainIngredient}
                    selectedRank1={selectedRank1}
                    selectedRank2={selectedRank2}
                    selectedRank3={selectedRank3}
                    selectedRank4={selectedRank4}
                    combinedPairingIngredients={combinedPairingIngredients}
                    setCombinedPairingIngredients={setCombinedPairingIngredients}
                />
            </div>
        </div>
    );
}

function FlavorAffinitiesDisplay({ flavorAffinities, mainIngredient, onAffinityClick }) {
    if (!mainIngredient) {
        return null;
    }

    return (
        <div className="w-full max-w-7xl p-4 py-4">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Flavor Affinities</h2>
            <div className="flex flex-wrap gap-4 justify-start">
                {flavorAffinities.map((affinity, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.2 }}
                        className="p-4 bg-white shadow-lg rounded-[20px] inline-flex items-center justify-center text-center border border-gray-200"
                    >
                        <div className="inline-flex items-center justify-center ">
                            {affinity.split(' + ').map((ingredient, i, arr) => (
                                <React.Fragment key={i}>
                                    <span
                                        onClick={() => onAffinityClick(ingredient)}
                                        className="text-lg font-medium text-gray-700 cursor-pointer mx-1 hover:text-blue-500"
                                    >
                                        {ingredient}
                                    </span>
                                    {i < arr.length - 1 && (
                                        <span className="text-lg font-medium text-gray-500 mx-1">+</span>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
}
