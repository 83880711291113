import React from "react";
import logo from "../logo.svg";

export default function Header() {
    return (
        <header className="sticky top-0 p-4 bg-gray-200 text-white text-center z-10">
            <div onClick={() => {
                window?.location?.reload();
            }}
                className="flex items-center justify-center gap-4 cursor-pointer">
                <img src={logo} alt="Logo" className="h-20" />
                <h1 className="text-2xl font-semibold text-black">Flavor Lab</h1>
            </div>
        </header>
    );
}
